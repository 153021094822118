import './App.css';
import React, { useState, useEffect, useLayoutEffect, useReducer, useContext, useCallback, useRef, memo } from 'react';
import { BrowserRouter as Router, Route, Redirect} from "react-router-dom";
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Slider from '@material-ui/core/Slider';
import { createTheme, ThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList as List, areEqual } from 'react-window';
import { isMobile } from 'react-device-detect';

import Anuncio from './Anuncio.js';
import Localizacao from './Localizacao.js';
import { BuscaCidadeAtual, BuscaDestaques, BuscaCategorias, BuscaAnuncios, BuscaAnunciosPesquisa, BuscaParametros, DescarregaCliques } from './Requisicoes.js';

import { dadosGlobaisContext } from './ContextGlobal.js';

import logoSemLupa from './imagens/logo_iqueest_sem_lupa_tr.png';
import lupaBuscando from './imagens/buscando.gif';
import logoiQueest from './imagens/logo_iqueest_oficial_pr.png';
import home from './imagens/home.png';
import homeBlack from './imagens/home-black.png';
import search from './imagens/search.png';
import searchBlack from './imagens/search-black.png';
import search2 from './imagens/search2.png';
import btRaio from './imagens/raio.png';
import promocao from './imagens/promocao.png';
import notification from './imagens/notification.png';
import check from './imagens/check.png';
import location from './imagens/location-black.png';
import close from './imagens/close.png';
import closeCorEsq from './imagens/close-coresq.png';
import closeWhite from './imagens/close-white.png';
import botaoApple from './imagens/botao_apple.png';
import botaoGoogle from './imagens/botao_google.png';

export const anunciosContext = React.createContext();
const categContext = React.createContext();

const dadosIniciais = {
    localizacao: "Atual",
    lat: "",
    lng: "",
    idCidadeAtual: null,
    cidadeAtual: "",
    idCidade: null,
    cidade: "",
    idCidadeCep: null,
    cidadeCep: "",
    idCep: null,
    cep: "",
    idCidadeLogra: null,
    cidadeLogra: "",
    idLogradouro: null,
    logradouro: "",
    latLocal: "",
    lngLocal: "",
    atualizaDestaques: false,
    ctrlProcessoAsync: "",
    raio: 0,
    raios: [0],
    limiteDestaques: null,
    larguraTela: null,
    alturaTela: null,
    idCliente: null,
    agendaAtuDestaques: false,
    atualizaFavoritos: false,
    dataNotificacoes: [],
    temNotificacaoNova: "N",
    nomeCliente: "",
    emailCliente: "",
    cliqueAjuda: false,
    ctrlDicas: false,
    contDicaMaisInfo: 0,
    contDicaRolaExp: 0,
    horaAtuDestaques: new Date(),
    userApple: "",
    atuLocalAtual: false,
    tokenFCM: "",
    dataAnuncioNotifica: null,
    contDicaExpande: 0,
    contDicaIrAnuncio: 0,
    contDicaCupom: 0,
    indAtual: 0,
    contDicaTemPromo: 0,
    contDicaCompartilha: 0
};

function reducerDadosGlobais(state, action) {
    switch (action.type) {
        case 'atuLocalizacao':
            return { ...state, localizacao: action.payload };
        case 'atuLat':
            return { ...state, lat: action.payload };
        case 'atuLng':
            return { ...state, lng: action.payload };
        case 'atuIdCidadeAtual':
            return { ...state, idCidadeAtual: action.payload };
        case 'atuCidadeAtual':
            return { ...state, cidadeAtual: action.payload };
        case 'atuIdCidade':
            return { ...state, idCidade: action.payload };
        case 'atuCidade':
            return { ...state, cidade: action.payload };
        case 'atuIdCidadeCep':
            return { ...state, idCidadeCep: action.payload };
        case 'atuCidadeCep':
            return { ...state, cidadeCep: action.payload };
        case 'atuIdCep':
            return { ...state, idCep: action.payload };
        case 'atuCep':
            return { ...state, cep: action.payload };
        case 'atuIdCidadeLogra':
            return { ...state, idCidadeLogra: action.payload };
        case 'atuCidadeLogra':
            return { ...state, cidadeLogra: action.payload };
        case 'atuIdLogradouro':
            return { ...state, idLogradouro: action.payload };
        case 'atuLogradouro':
            return { ...state, logradouro: action.payload };
        case 'atuLatLocal':
            return { ...state, latLocal: action.payload };
        case 'atuLngLocal':
            return { ...state, lngLocal: action.payload };
        case 'atuAtualizaDestaques':
            return { ...state, atualizaDestaques: action.payload };
        case 'atuCtrlProcessoAsync':
            return { ...state, ctrlProcessoAsync: action.payload };
        case 'atuRaio':
            return { ...state, raio: action.payload };
        case 'atuRaios':
            return { ...state, raios: action.payload };
        case 'atuLimiteDestaques':
            return { ...state, limiteDestaques: action.payload };
        case 'atuLarguraTela':
            return { ...state, larguraTela: action.payload };
        case 'atuAlturaTela':
            return { ...state, alturaTela: action.payload };
        case 'atuIdCliente':
            return { ...state, idCliente: action.payload };
        case 'atuAgendaAtuDestaques':
            return { ...state, agendaAtuDestaques: action.payload };
        case 'atuAtualizaFavoritos':
            return { ...state, atualizaFavoritos: action.payload };
        case 'atuDataNotificacoes':
            return { ...state, dataNotificacoes: action.payload };
        case 'atuTemNotificacaoNova':
            return { ...state, temNotificacaoNova: action.payload };
        case 'atuNomeCliente':
            return { ...state, nomeCliente: action.payload };
        case 'atuEmailCliente':
            return { ...state, emailCliente: action.payload };
        case 'atuCliqueAjuda':
            return { ...state, cliqueAjuda: action.payload };
        case 'atuCtrlDicas':
            return { ...state, ctrlDicas: action.payload };
        case 'atuContDicaMaisInfo':
            return { ...state, contDicaMaisInfo: action.payload };
        case 'atuContDicaRolaExp':
            return { ...state, contDicaRolaExp: action.payload };
        case 'atuHoraAtuDestaques':
            return { ...state, horaAtuDestaques: action.payload };
        case 'atuUserApple':
            return { ...state, userApple: action.payload };
        case 'atuAtuLocalAtual':
            return { ...state, atuLocalAtual: action.payload };
        case 'atuTokenFCM':
            return { ...state, tokenFCM: action.payload };
        case 'atuDataAnuncioNotifica':
            return { ...state, dataAnuncioNotifica: action.payload };
        case 'atuContDicaExpande':
            return { ...state, contDicaExpande: action.payload };
        case 'atuContDicaIrAnuncio':
            return { ...state, contDicaIrAnuncio: action.payload };
        case 'atuContDicaCupom':
            return { ...state, contDicaCupom: action.payload };
        case 'atuIndAtual':
            return { ...state, indAtual: action.payload };
        case 'atuContDicaTemPromo':
            return { ...state, contDicaTemPromo: action.payload };
        case 'atuContDicaCompartilha':
            return { ...state, contDicaCompartilha: action.payload };
        default:
            return state;
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#BEBEBE',
        },
        text: {
            primary: '#000000',
            secondary: '#4F4F4F',
            disabled: '#A9A9A9',
            hint: '#F9C205',
        }
    },
    overrides: {
        MuiInputBase: {
            input: {
                '&$disabled': {
                    "-webkit-text-fill-color": '#A9A9A9'
                }
            }
        }
    },
});

const useStyles = makeStyles((theme) => ({
    gridEmForm: {
        marginBottom: '20px',
        alignItems: 'center'
    },
    fonteMiniTextField: {
        fontSize: 10
    },
    fonteMenorTextField: {
        fontSize: 12
    },
    fonteMenorInputLabel: {
        fontSize: 12
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    scrollbar: {
        "&::-webkit-scrollbar": {
            width: 0,
            height: 4
        },
        "&:hover::-webkit-scrollbar": {
            width: 4,
            height: 4
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
            borderRadius: 10
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "darkgrey",
            outline: `1px solid slategrey`,
            borderRadius: 10
        },
    },
    scrollbar2: {
        "&::-webkit-scrollbar": {
            width: 0,
            height: 0
        },
        "&:hover::-webkit-scrollbar": {
            width: 4,
            height: 0
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
            borderRadius: 10
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "darkgrey",
            outline: `1px solid slategrey`,
            borderRadius: 10
        },
    },
}));

const SliderRaio = withStyles({
    root: {
        color: '#F9C205',
        height: 12,
    },
    thumb: {
        height: 10,
        width: 5,
        color: '#F8961B',
        border: '2px solid #F8961B',
        marginTop: 3,
        marginLeft: 0,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: -20,
        top: -20,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 12,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: 'gray',
        borderRadius: 5,
    },
    rail: {
        height: 12,
        color: '#696969',
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: 'black',
        borderRadius: 5,
    },
    mark: {
        backgroundColor: 'gray',
        height: 14,
        width: 3,
        marginTop: 0,
    },
})(Slider);

function Categ(props) {
    const setLargura = useContext(categContext);
    const wRef = useRef();

    useEffect(() => {
        setLargura(props.ind, wRef.current.getBoundingClientRect().width + 10);
    });

    return (
        <div style={{ width: 'fit-content' }} ref={wRef}>
            <IconButton size="small" onClick={() => { props.informaPaiCatSel(props.categoria) }}>
                <span style={{ color: '#4F4F4F' }}>{props.categoria}</span>
            </IconButton>
        </div>
    );
}

const renderCateg = ({ data, index, style }) => {
    const { categorias, informaCatSel } = data;

    return (
        <div style={style}>
            <Categ ind={index}
                categoria={categorias[index]}
                informaPaiCatSel={informaCatSel} />
        </div>
    );
};

const renderItem = memo(({ data, index, style }) => {
    const { dataAnuncios, informaClicouFavorito, informaClicouPromocao } = data;

    return (
        <div style={style}>
            <Anuncio
                key={index.toString()}
                ind={index}
                titulo={dataAnuncios[index].titulo}
                url={dataAnuncios[index].url}
                cidade_uf={dataAnuncios[index].cidade_uf}
                km={dataAnuncios[index].km}
                descricao={dataAnuncios[index].descricao}
                grifar={dataAnuncios[index].grifar}
                id={dataAnuncios[index].id}
                lat={dataAnuncios[index].lat}
                lng={dataAnuncios[index].lng}
                whatsapp={dataAnuncios[index].whatsapp}
                instagram={dataAnuncios[index].instagram}
                facebook={dataAnuncios[index].facebook}
                youtube={dataAnuncios[index].youtube}
                twitter={dataAnuncios[index].twitter}
                linkedin={dataAnuncios[index].linkedin}
                site={dataAnuncios[index].site}
                telefone={dataAnuncios[index].telefone}
                seg={dataAnuncios[index].seg}
                ter={dataAnuncios[index].ter}
                qua={dataAnuncios[index].qua}
                qui={dataAnuncios[index].qui}
                sex={dataAnuncios[index].sex}
                sab={dataAnuncios[index].sab}
                dom={dataAnuncios[index].dom}
                disponibilidade={dataAnuncios[index].disponibilidade}
                linkappmapas={dataAnuncios[index].linkappmapas}
                tempromo={dataAnuncios[index].tempromo}
                clicouFavorito={informaClicouFavorito}
                clicouPromocao={informaClicouPromocao} />
        </div>
    );
}, areEqual);

var wUnidadeLargura = 0;
var vRaios = [];
var wSubCat = "";

function App(props) {
    const [dataAnuncios, setDataAnuncios] = useState(null);
    const [dataCategorias, setDataCategorias] = useState([]);
    const [dataAnunciosDestaques, setDataAnunciosDestaques] = useState(null);
    const [dataAnunciosCategorias, setDataAnunciosCategorias] = useState(null);
    const [dataAnunciosPesquisa, setDataAnunciosPesquisa] = useState(null);
    const [dataParametros, setDataParametros] = useState(null);
    const [buscando, setBuscando] = useState(false);
    const [infoNaoCarrega, setInfoNaoCarrega] = useState(false);
    const [pesquisa, setPesquisa] = useState(false);
    const [palavras, setPalavras] = useState(null);
    const [palavrasClone, setPalavrasClone] = useState(null);
    const [ctrlHome, setCtrlHome] = useState(-1);
    const [raio, setRaio] = useState(null);
    const [auxRaio, setAuxRaio] = useState(null);
    const [mensagem, setMensagem] = useState("");
    const [acao, setAcao] = useState(0);
    const [categoriaSel, setCategoriaSel] = useState('');
    const [subcatSel, setSubcatSel] = useState('');
    const [mostraSubcats, setMostraSubcats] = useState(false);
    const [alturaListaAnuncios, setAlturaListaAnuncios] = useState(null);
    const [baseLargura, setBaseLargura] = useState(0);
    const [mostraRaios, setMostraRaios] = useState(false);
    const [telaLocalizacao, setTelaLocalizacao] = useState(false);
    const [ctrlLocalizacao, setCtrlLocalizacao] = useState(0);
    const [mostraCardArea, setMostraCardArea] = useState(false);
    const [ctrlMostraCardArea, setCtrlMostraCardArea] = useState(0);
    const [mostraCardSite, setMostraCardSite] = useState(false);
    const [ctrlMostraCardSite, setCtrlMostraCardSite] = useState(0);
    const [mostraDlgInstalarApp, setMostraDlgInstalarApp] = useState(false);
    const [msgDlgInstalarApp, setMsgDlgInstalarApp] = useState("");
    const [mostraRotuloInstalarApp, setMostraRotuloInstalarApp] = useState(false);
    const [ctrlMostraRotuloInstalarApp, setCtrlMostraRotuloInstalarApp] = useState(0);
    const [categorias, setCategorias] = useState([]);

    const [dadosGlobais, dispatchDadosGlobais] = useReducer(reducerDadosGlobais, dadosIniciais);

    const classes = useStyles();

    const wRefListAnuncios = useRef();

    const wList2Ref = useRef();
    const vLargurasRef = useRef({});
    const setLargura = useCallback((index, size) => {
        vLargurasRef.current = { ...vLargurasRef.current, [index]: size };
        wList2Ref.current.resetAfterIndex(0, false);
    }, []);
    const getLargura = useCallback((index) => vLargurasRef.current[index] || 80, []);

    const wListRef = useRef();
    const vAlturasRef = useRef({});
    const setAltura = useCallback((index, size) => {
        vAlturasRef.current = { ...vAlturasRef.current, [index]: size };
        wListRef.current.resetAfterIndex(0);
    }, []);
    const getAltura = useCallback((index) => vAlturasRef.current[index] || 360, []);

    window.onresize = (event) => {
        if (wRefListAnuncios.current) {
            setAlturaListaAnuncios(window.innerHeight - wRefListAnuncios.current.getBoundingClientRect().top - 12);
        }
    }

    document.onvisibilitychange = (event) => {
        if (document.visibilityState === 'hidden') {
            DescarregaCliques();
        }
    }

    useEffect(() => {
        if (ctrlLocalizacao == 0 && dadosGlobais.localizacao == "Atual" && dadosGlobais.lat == "" && dadosGlobais.lng == "" && dataAnunciosDestaques == null) {
            setDataAnuncios(null);
            setBuscando(true);
            buscaDadosAnunciosDestaques();
        }
    }, [ctrlLocalizacao, dadosGlobais.localizacao, dadosGlobais.lat, dadosGlobais.lng]);

    useEffect(() => {
        if (dadosGlobais.atualizaDestaques == true) {
            setDataAnuncios(null);
            setBuscando(true);
            buscaDadosAnunciosDestaques();
        }
    }, [dadosGlobais.atualizaDestaques]);

    useEffect(() => {
        if (ctrlLocalizacao == 1 && telaLocalizacao == false && dadosGlobais.localizacao == "Atual" && (dadosGlobais.lat == "" || dadosGlobais.lng == "")) {
            setMensagem('Como não conseguimos acesso à sua localização, será preciso escolher uma localização para continuar usando o iQueest.');
            setTelaLocalizacao(true);
            setCtrlHome(1);
        }
    }, [telaLocalizacao, ctrlLocalizacao, dadosGlobais.localizacao, dadosGlobais.lat, dadosGlobais.lng]);

    useEffect(() => {
        if (telaLocalizacao == false && ctrlLocalizacao == 1) {
            if (dadosGlobais.atualizaDestaques !== true) {
                setCtrlHome(0);
            }
        }
    }, [telaLocalizacao, ctrlLocalizacao, dadosGlobais.atualizaDestaques])

    useEffect(() => {
        if (ctrlLocalizacao == 1) {
            setCtrlLocalizacao(0);
        }
    }, [ctrlLocalizacao])

    useEffect(() => {
        if (ctrlHome == 0) {
            wSubCat = "";

            setDataAnunciosCategorias(null);
            setDataAnunciosPesquisa(null);
            setDataAnuncios(dataAnunciosDestaques);
            setCategoriaSel("");
            setSubcatSel("");
            setPalavras("");
            setPalavrasClone("");
            setPesquisa(true);
        }
    }, [ctrlHome]);

    useEffect(() => {
        if (ctrlHome <= 0 && dataAnuncios != null) {
            if (ctrlMostraCardArea < 3 && mostraCardArea == false) {
                setCtrlMostraCardArea((cont) => cont + 1);
                setMostraCardArea(true);
            }

            if (ctrlMostraCardSite < 1 && mostraCardSite == false) {
                setCtrlMostraCardSite((cont) => cont + 1);
                setMostraCardSite(true);
                setTimeout(() => {
                    setMostraCardSite(false);
                }, 8000);
            }

            if (ctrlMostraRotuloInstalarApp < 1 && mostraRotuloInstalarApp == false) {
                setCtrlMostraRotuloInstalarApp((cont) => cont + 1);
                setMostraRotuloInstalarApp(true);
            }
        }
    }, [ctrlHome, dataAnuncios])

    useEffect(() => {
        // AÇÕES DISPARADAS POR <OK> DE MENSAGENS 
        if (acao > 0) {
            // LOCALIZAÇÃO INDUZIDA PARA CIDADES QUE O IQUEEST NÃO ESTÁ OPERANDO
            if (acao == 1) {
                dispatchDadosGlobais({ type: 'atuLat', payload: "-30.06421" });
                dispatchDadosGlobais({ type: 'atuLng', payload: "-51.16435" });
                dispatchDadosGlobais({ type: 'atuIdCidadeAtual', payload: 7994 });
                dispatchDadosGlobais({ type: 'atuCidadeAtual', payload: "Porto Alegre" });
                dispatchDadosGlobais({ type: 'atuLocalizacao', payload: "Induzida" });
                dispatchDadosGlobais({ type: 'atuAtualizaDestaques', payload: true });
            }

            setAcao(0);
        }
    }, [acao])

    async function buscaDadosAnunciosDestaques() {
        var wAuxDataCategorias = [];
        var wAuxDataAnuncios = [];
        var wAuxDataParametros = undefined;
        var wAuxCidade = 0;
        var wAuxLat = "";
        var wAuxLng = "";
        var wBuscaCategoria = false;
        var wAuxCategoria = null;

        for (let i = 0; i <= 7; i++) {
            if (i == 0) {
                if (dadosGlobais.localizacao != "Atual") {
                    if (dadosGlobais.localizacao == "Cidade") {
                        wAuxCidade = dadosGlobais.idCidade
                    }
                    else if (dadosGlobais.localizacao == "Cep") {
                        wAuxCidade = dadosGlobais.idCidadeCep
                        wAuxLat = dadosGlobais.latLocal;
                        wAuxLng = dadosGlobais.lngLocal;
                    }
                    else if (dadosGlobais.localizacao == "Logradouro") {
                        wAuxCidade = dadosGlobais.idCidadeLogra
                        wAuxLat = dadosGlobais.latLocal;
                        wAuxLng = dadosGlobais.lngLocal;
                    }
                    else if (dadosGlobais.localizacao == "Induzida") {
                        wAuxCidade = dadosGlobais.idCidadeAtual
                        wAuxLat = dadosGlobais.lat;
                        wAuxLng = dadosGlobais.lng;
                    }
                }
                else {
                    await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition((position) => {
                            wAuxLat = position.coords.latitude.toString();
                            wAuxLng = position.coords.longitude.toString()
                            dispatchDadosGlobais({ type: 'atuLat', payload: position.coords.latitude.toString() });
                            dispatchDadosGlobais({ type: 'atuLng', payload: position.coords.longitude.toString() });
                            resolve([position.coords.latitude.toString(), position.coords.longitude.toString()]);
                        }, (error) => {
                            setMensagem('Como não conseguimos acesso à sua localização, será preciso escolher uma localização para continuar usando o iQueest.');
                            setTelaLocalizacao(true);
                            setCtrlHome(1);
                        });
                    })
                    .then(BuscaCidadeAtual)
                    .then((rAuxDataCidadeAtual) => {
                        wAuxCidade = rAuxDataCidadeAtual[0].id;
                        dispatchDadosGlobais({ type: 'atuIdCidadeAtual', payload: rAuxDataCidadeAtual[0].id });
                        dispatchDadosGlobais({ type: 'atuCidadeAtual', payload: rAuxDataCidadeAtual[0].nome });
                        dispatchDadosGlobais({ type: 'atuLocalizacao', payload: "Atual" });
                    })
                    .catch((error) => { })
                }
            }

            if (i == 1) {
                await BuscaCategorias()
                    .then((rAuxDataCategorias) => wAuxDataCategorias = rAuxDataCategorias)
                    .catch((error) => { });
            }

            if (i == 2) {
                await BuscaParametros()
                    .then((rAuxDataParametros) => wAuxDataParametros = rAuxDataParametros)
                    .catch((error) => { });
            }

            if (i == 3) {
                await DescarregaCliques()
                    .catch((error) => { });
                //.then((res) => { window.alert("TESTE") })
            }

            if (i == 4) {
                if (ctrlHome == -1) {
                    let wUrl = window.location.href;

                    //if (wUrl.indexOf("localhost:3000") + 15 < wUrl.length - 1) {
                    if (wUrl.indexOf("iqueest.com.br") + 15 < wUrl.length - 1) {
                        //wSubCat = wUrl.substring(wUrl.indexOf("localhost:3000") + 15);
                        wSubCat = wUrl.substring(wUrl.indexOf("iqueest.com.br") + 15);
                        if (wSubCat.endsWith("/")) {
                            wSubCat = wSubCat.substring(0, wSubCat.length - 1);
                        }

                        if (wAuxDataCategorias.filter(x => convertePalavra(x.subcategoria) == wSubCat).length > 0) {
                            wAuxCategoria = wAuxDataCategorias.filter(x => convertePalavra(x.subcategoria) == wSubCat)[0]
                            wBuscaCategoria = true;

                            i = 6;
                            continue;
                        }
                    }
                }
            }

            if (i == 5) {
                let sai = false;
                if (wAuxCidade != 0) {
                    await BuscaDestaques(wAuxCidade, wAuxLat, wAuxLng, 100000000)
                        .then((rAuxDataAuncios) => {
                            if (rAuxDataAuncios.length == 0) {
                                setAcao(-1);
                                setMensagem('Não encontramos Anúncios em destaque para a cidade da sua Localização. ' +
                                    'O iQueest ainda não está operando na sua cidade. Para uma melhor experiência, vamos direcionar sua Localização para uma cidade válida: Porto Alegre-RS');
                                sai = true;
                            }
                            else {
                                wAuxDataAnuncios = rAuxDataAuncios;
                            }
                        })
                        .catch((error) => { });
                }
                if (sai == true) {
                    dispatchDadosGlobais({ type: 'atuAtualizaDestaques', payload: false });
                    setBuscando(false);
                    break;
                }
            }

            if (i == 6) {
                // ROTINA ALTERNATIVA DE ANUNCIOS EM DESTAQUE QUANDO A CIDADE POSSUI DESTAQUES MENOS DE 4
                if (wAuxDataAnuncios.length < 4) {
                    await BuscaDestaques(-1, "", "", 100000000)
                        .then((rAuxDataAuncios) => Array.prototype.push.apply(wAuxDataAnuncios, rAuxDataAuncios.filter((item, indice, vetor) => { return indice + 1 + wAuxDataAnuncios.length <= 4 })))
                        .catch(() => { })
                }
            }

            if (i == 7) {
                if (wAuxDataCategorias.length > 0) {
                    setDataCategorias(wAuxDataCategorias);
                }

                if (wAuxDataAnuncios.length > 0) {
                    setDataAnunciosDestaques(wAuxDataAnuncios.filter(x => x.id <= 9999999));
                    setDataAnuncios(wAuxDataAnuncios.filter(x => x.id <= 9999999));
                }

                if (wAuxDataParametros != undefined) {
                    setDataParametros(wAuxDataParametros);
                    setRaio(wAuxDataParametros.raios[2]);

                    for (let r = 0; r < wAuxDataParametros.raios.length; r++) {
                        vRaios[r] = {
                            value: wAuxDataParametros.raios[r],
                            label: wAuxDataParametros.raios[r].toString() + 'Km'
                        }
                    }
                }

                dispatchDadosGlobais({ type: 'atuAtualizaDestaques', payload: false });

                if (wBuscaCategoria == true) {
                    setCategoriaSel(wAuxCategoria.categoria);
                    setSubcatSel(wAuxCategoria.id);
                }
                else {
                    setCtrlHome(0);
                    setBuscando(false);
                }
            }
        }
    }

    async function buscaDadosAnunciosCategorias(fRaio) {
        var wAuxDataAnuncios = [];
        var wAuxCidade = 0;
        var wAuxLat = "";
        var wAuxLng = "";

        if (dadosGlobais.localizacao == "Atual" || dadosGlobais.localizacao == "Induzida") {
            wAuxLat = dadosGlobais.lat;
            wAuxLng = dadosGlobais.lng;
        }
        else if (dadosGlobais.localizacao == "Cidade") {
            wAuxCidade = dadosGlobais.idCidade
        }
        else if (dadosGlobais.localizacao == "Cep") {
            wAuxLat = dadosGlobais.latLocal;
            wAuxLng = dadosGlobais.lngLocal;
        }
        else if (dadosGlobais.localizacao == "Logradouro") {
            wAuxLat = dadosGlobais.latLocal;
            wAuxLng = dadosGlobais.lngLocal;
        }

        for (let i = 0; i <= 0; i++) {
            if (i == 0) {
                await BuscaAnuncios(subcatSel, fRaio, wAuxCidade, wAuxLat, wAuxLng, 100000000)
                    .then((rAuxDataAuncios) => wAuxDataAnuncios = rAuxDataAuncios)
                    .catch(() => { })
            }
        }

        if (wAuxDataAnuncios.length > 0) {
            setDataAnunciosPesquisa(null);
            setDataAnunciosCategorias(wAuxDataAnuncios.filter(x => x.id <= 9999999));
            setDataAnuncios(wAuxDataAnuncios.filter(x => x.id <= 9999999));
            setCtrlHome(1);
        }
        else {
            setDataAnunciosCategorias(null);
            setPesquisa(true);
            setDataAnuncios(dataAnunciosDestaques);
            setMensagem('Não encontramos Anúncios para a Categoria selecionada usando os critérios de busca atuais. Sugerimos que tente aumentar o raio de busca.');
            setCategoriaSel("");
            setSubcatSel("");
        }

        setBuscando(false);
    }

    async function buscaDadosAnunciosPesquisa(fRaio) {
        var wAuxDataAnuncios = [];
        var wAuxCidade = 0;
        var wAuxLat = "";
        var wAuxLng = "";

        if (dadosGlobais.localizacao == "Atual" || dadosGlobais.localizacao == "Induzida") {
            wAuxLat = dadosGlobais.lat;
            wAuxLng = dadosGlobais.lng;
        }
        else if (dadosGlobais.localizacao == "Cidade") {
            wAuxCidade = dadosGlobais.idCidade
        }
        else if (dadosGlobais.localizacao == "Cep") {
            wAuxLat = dadosGlobais.latLocal;
            wAuxLng = dadosGlobais.lngLocal;
        }
        else if (dadosGlobais.localizacao == "Logradouro") {
            wAuxLat = dadosGlobais.latLocal;
            wAuxLng = dadosGlobais.lngLocal;
        }

        for (let i = 0; i <= 0; i++) {
            if (i == 0) {
                await BuscaAnunciosPesquisa(palavras, fRaio, wAuxCidade, wAuxLat, wAuxLng, 100000000)
                    .then((rAuxDataAuncios) => wAuxDataAnuncios = rAuxDataAuncios)
                    .catch(() => { })
            }
        }

        if (wAuxDataAnuncios.length > 0) {
            setDataAnunciosCategorias(null);
            setDataAnunciosPesquisa(wAuxDataAnuncios);
            setDataAnuncios(wAuxDataAnuncios);
            setPalavrasClone(palavras);
            setCtrlHome(1);
        }
        else {
            setDataAnunciosPesquisa(null);
            setPesquisa(true);
            setDataAnuncios(dataAnunciosDestaques);
            setMensagem('Não encontramos Anúncios usando os critérios de busca atuais. Sugerimos que tente aumentar o raio de busca.');
            setPalavras("");
        }

        setBuscando(false);
    }

    function convertePalavra(text) {
        const a = 'àáäâãèéëêìíïîòóöôõùúüûñçŕśńṕẃǵǹḿǘẍźḧ·/_,:; &';
        const b = 'aaaaaeeeeiiiiooooouuuuncrsnpwgnmuxzh-------e';

        let wPalavra = text.toString().toLowerCase().trim().split('').map(z => z.replace(a.charAt(a.indexOf(z)), b.charAt(a.indexOf(z)))).join('');

        return wPalavra;
   }

    const renderSubcat = ({ index, style }) => (
        <div style={style}>
            <IconButton onClick={() => { setSubcatSel(dataCategorias.filter(x => x.categoria === categoriaSel)[index].id) }}>
                <Grid direction="column">
                    <div style={{ textAlign: 'left', color: "#4F4F4F", fontSize: 15, width: baseLargura * 0.45 * 0.85, height: 35 }}>{dataCategorias.filter(x => x.categoria === categoriaSel)[index].subcategoria}</div>
                    <hr style={{ marginTop: -10 }} />
                </Grid>
            </IconButton>
        </div>
    );

    function categoriasDistintas(item, indice, vetor) {
        return vetor.indexOf(item) === indice;
    }

    function informaCatSel(fCategoria) {
        setCategoriaSel(fCategoria);
        setMostraSubcats(true);
    }

    function ctrlClicouForaSubcats() {
        if (mostraSubcats == true) {
            setMostraSubcats(false);
        }
    }

    function alteraRaio() {
        setMostraRaios(false);
        setRaio(auxRaio);
        setAuxRaio(null);
    }

    function informaRetornaLocalizacao() {
        setCtrlLocalizacao(1);
        setTelaLocalizacao(false);
    }

    function ctrlClicouForaDlgInstalarApp() {
        if (mostraDlgInstalarApp == true) {
            setMostraDlgInstalarApp(false);
        }
    }

    function informaClicouFavorito() {
        setMsgDlgInstalarApp("Para tornar o Anúncio um de seus favoritos, você precisa instalar o iQueest na versão em aplicativo. Lá, você pode criar sua conta de usuário, e ter a sua coleção de anúncios favoritos! Sua experiência no iQueest será muito mais completa se você optar pela instalação do App em seu dispositivo!");
        setMostraDlgInstalarApp(true);
    }

    function informaClicouPromocao() {
        setMsgDlgInstalarApp("Para ter acesso às Promoções, você precisa instalar o iQueest na versão em aplicativo. Sua experiência no iQueest será muito mais completa se você optar pela instalação do App em seu dispositivo. Vá até sua loja de aplicativos através de um dos botões abaixo, e instale agora mesmo!");
        setMostraDlgInstalarApp(true);
    }

    useEffect(() => {
        if (dataCategorias && dataCategorias.length > 0) {
            setCategorias(dataCategorias.map((item) => { return item.categoria }).filter(categoriasDistintas));
        }
    }, [dataCategorias]);

    useEffect(() => {
        if (mostraSubcats == false && subcatSel == "") {
            setCategoriaSel("");
        }
    }, [mostraSubcats])

    useEffect(() => {
        if (subcatSel != "") {
            if (convertePalavra(dataCategorias.filter(x => x.id == subcatSel)[0].subcategoria) != wSubCat) {
                wSubCat = "";
            }

            setMostraSubcats(false);
            setDataAnuncios(null);
            setBuscando(true);
            setPesquisa(false);
            buscaDadosAnunciosCategorias(raio);
        }
    }, [subcatSel])

    useEffect(() => {
        if (pesquisa == false) {
            setDataAnunciosPesquisa(null);

            if (buscando == false) {
                setDataAnuncios(dataAnunciosDestaques);
            }

            setPalavras("");
            setPalavrasClone("");

            // PRA NÃO DISPARAR O EEITO DE CTRLHOME = 0, MAS DESABILITAR HOME. PORQUE O EFEITO DE CTRLHOME = 0 HABILITA A PESQUISA. E AQUI ACABOU DE DESABILITAR
            setCtrlHome(-1);
        }
        else {
            setPalavras("");
            setPalavrasClone("");
            setCtrlHome(0);
        }

    }, [pesquisa]);

    useEffect(() => {
        if (pesquisa == true && document.getElementById("textPesquisa")) {
            if (!isMobile) {
                document.getElementById("textPesquisa").focus();
            }
        }
    }, [pesquisa])

    useEffect(() => {
        if (dataAnunciosCategorias || dataAnunciosPesquisa) {
            setDataAnuncios(null);
            setBuscando(true);
            if (dataAnunciosCategorias && subcatSel != "") {
                buscaDadosAnunciosCategorias(raio);
            }
            else if (dataAnunciosPesquisa && palavras && palavras.length > 0) {
                buscaDadosAnunciosPesquisa(raio)
            }
        }
    }, [raio])

    useEffect(() => {
        wUnidadeLargura = baseLargura / 400;
    }, [baseLargura]);

    useEffect(() => {
        if (baseLargura == 0) {
            // BASEADO EM window.innerWidth   >= 1200 (lg)   OU   >= 600 (sm)   OU   >= 0 (xs)
            if (window.innerWidth >= 1200) {
                setBaseLargura(window.innerWidth / 12 * 4);
            }
            else if (window.innerWidth >= 600) {
                setBaseLargura(window.innerWidth / 12 * 8);
            }
            else {
                setBaseLargura(window.innerWidth * 0.98); // USADO 0.98 PORQUE SE USAR 100% DE innerWidth DÁ UM MONTE DE PROBLEMAS EM NAVEGADOR MOBILE
            }
        }

        if (wRefListAnuncios.current) {
            setAlturaListaAnuncios(window.innerHeight - wRefListAnuncios.current.getBoundingClientRect().top - 12);
        }
    });

    return (
        <dadosGlobaisContext.Provider value={[dadosGlobais, dispatchDadosGlobais]}>
        <ThemeProvider theme={theme}>
            <div className="App">
                <Grid container xs={12} direction="column" alignItems="center">
                    <Grid container item xs={12} sm={8} lg={4} direction="column" alignItems="center" justifyContent="flex-start">
                        {telaLocalizacao ?
                            <Localizacao retorna={informaRetornaLocalizacao}/>
                        :
                            <>
                            <Router>
                            <Grid container item direction="column" alignItems="flex-start" justifyContent="flex-start">
                                <Grid container item direction="row" xs={12} style={{ height: '100%', width: baseLargura }} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <IconButton onClick={() => { window.open("https://www.iqueest.net.br/", "_blank"); }}>
                                            <img
                                                style={{ height: 56, resizeMode: 'stretch' }}
                                                src={logoiQueest}
                                            />
                                        </IconButton>
                                    </Grid>
                                    <Grid item direction="row" alignItems="center" justifyContent="center">
                                        <IconButton size="small" disabled={ctrlHome == 0 ? true : false} onClick={() => { 
                                            if (dataAnunciosDestaques != null) {
                                                setCtrlHome(0);
                                            }
                                            else {
                                                dispatchDadosGlobais({ type: 'atuAtualizaDestaques', payload: true });
                                            }
                                        }}>
                                            <img
                                                style={{ width: 22, height: 23, resizeMode: 'stretch' }}
                                                src={ctrlHome <= 0 ? home : homeBlack}
                                            />
                                        </IconButton>
                                        <IconButton size="small" disabled={false} onClick={() => {
                                            setTelaLocalizacao(true);
                                            setCtrlHome(1);
                                        }}>
                                            <img
                                                style={{ width: 22, height: 23, resizeMode: 'stretch' }}
                                                src={location}
                                            />
                                        </IconButton>
                                        <IconButton size="small" disabled={false} onClick={() => {
                                            setMsgDlgInstalarApp("Para ter acesso às Promoções, você precisa instalar o iQueest na versão em aplicativo. Sua experiência no iQueest será muito mais completa se você optar pela instalação do App em seu dispositivo. Vá até sua loja de aplicativos através de um dos botões abaixo, e instale agora mesmo!");
                                            setMostraDlgInstalarApp(true);
                                        }}>
                                            <img
                                                style={{ width: 22, height: 23, resizeMode: 'stretch' }}
                                                src={promocao}
                                            />
                                        </IconButton>
                                        <IconButton size="small" disabled={false} onClick={() => { 
                                            setAuxRaio(raio);
                                            setMostraRaios((r) => !r);
                                        }}>
                                            <img
                                                style={{ width: 22, height: 23, resizeMode: 'stretch' }}
                                                src={btRaio}
                                            />
                                            <span style={{ color: '#F8961B', marginLeft: -3, marginTop: -15, fontSize: 9, fontWeight: 'bold' }}>{raio}</span>
                                        </IconButton>
                                        <IconButton size="small" disabled={false} onClick={() => setPesquisa((p) => !p)}>
                                            <img
                                                style={{ width: 22, height: 23, resizeMode: 'stretch' }}
                                                src={pesquisa ? search : searchBlack}
                                            />
                                        </IconButton>
                                        <IconButton size="small" disabled={false} onClick={() => {
                                            setMsgDlgInstalarApp("Para ter acesso às Notificações, você precisa instalar o iQueest na versão em aplicativo. Sua experiência no iQueest será muito mais completa se você optar pela instalação do App em seu dispositivo. Vá até sua loja de aplicativos através de um dos botões abaixo, e instale agora mesmo!");
                                            setMostraDlgInstalarApp(true);
                                        }}>
                                            <img
                                                style={{ width: 22, height: 23, resizeMode: 'stretch' }}
                                                src={notification}
                                            />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" xs={12} style={{ height: '100%', width: baseLargura }} alignItems="flex-start" justifyContent="flex-start">
                                    {categoriaSel == "" ?
                                        <categContext.Provider value={setLargura}>
                                            <List className={classes.scrollbar}
                                                itemKey={(index, data) => {
                                                    const { categorias, informaCatSel } = data
                                                    return categorias[index];
                                                }}
                                                itemData={{ categorias, informaCatSel }}
                                                ref={wList2Ref}
                                                height={40}
                                                width={baseLargura}
                                                layout="horizontal"
                                                itemCount={categorias.length}
                                                itemSize={getLargura}>

                                                {renderCateg}
                                            </List>
                                        </categContext.Provider>
                                    :
                                        <Grid container item direction="row" style={{ height: 40 }} alignItems="center">
                                            <IconButton size="small" onClick={() => {
                                                if (dataAnunciosDestaques != null) {
                                                    setCtrlHome(0);
                                                }
                                                else {
                                                    dispatchDadosGlobais({ type: 'atuAtualizaDestaques', payload: true });
                                                }
                                            }}>
                                                <span style={{ color: '#F8961B', fontWeight: 'bold' }}>{categoriaSel}</span>
                                            </IconButton>
                                            {subcatSel != "" ?
                                                <>
                                                    <span style={{ color: '#4F4F4F', fontWeight: 'bold' }}>  >  </span>
                                                    <IconButton size="small" onClick={() => { setMostraSubcats(true) }}>
                                                        <span style={{ color: '#F9C205', fontWeight: 'bold' }}>{dataCategorias.filter(x => x.id === subcatSel)[0].subcategoria}</span>
                                                    </IconButton>
                                                </>
                                            :
                                                null
                                            }
                                        </Grid>
                                    }
                                </Grid>                                         
                                <Grid item container direction="column" xs={12} style={{ width: baseLargura }} alignItems="flex-start" justifyContent="flex-start">
                                    {mostraRaios ?
                                        <Grid container item direction="column" style={{ width: baseLargura, marginTop: 5, borderStyle: 'solid', borderWidth: 1, borderColor: 'silver', borderRadius: 5, padding: 5 }} justifyContent="flex-start" alignItems="center">
                                            <Grid container item direction="row" style={{ width: baseLargura, marginBottom: 8 }} justifyContent="flex-start" alignItems="center">
                                                <SliderRaio style={{ margin: 2, width: '88%' }} onChange={(event, value) => setAuxRaio(value)}
                                                    defaultValue={raio}
                                                    step={null}
                                                    marks={vRaios}
                                                    valueLabelDisplay="on"
                                                    valueLabelFormat={(value) => vRaios.find((r) => r.value === value).label}
                                                />
                                                <IconButton style={{ marginLeft: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#696969', borderRadius: 5, padding: 0 }} size="small" disabled={false} onClick={alteraRaio}>
                                                    <img
                                                        style={{ width: 20, height: 20, resizeMode: 'stretch' }}
                                                        src={check}
                                                    />
                                                </IconButton>
                                            </Grid>
                                            <p className="FonteDicas">Ajuste o raio de busca</p>
                                        </Grid>
                                    :
                                        null
                                    }
                                </Grid>
                                <Grid item container direction="column" xs={12} style={{ height: '100%', width: baseLargura }} alignItems="flex-start" justifyContent="flex-start">
                                    {pesquisa ?
                                        <TextField style={{ marginTop: 10 }}
                                            id="textPesquisa"
                                            name="pesquisa"
                                            label="Pesquisa"
                                            variant="outlined"
                                            size="small"
                                            value={palavras}
                                            onChange={(event) => {
                                                const wVal = event.target.value;
                                                setPalavras(wVal);
                                            }}
                                            onKeyPress={(event) => {
                                                if (event.key == "Enter") {
                                                    if (palavras.length > 0) {
                                                        setDataAnuncios(null);
                                                        setBuscando(true);
                                                        buscaDadosAnunciosPesquisa(raio);
                                                    }
                                                    else {
                                                        setMensagem("Digite ao menos uma palavra para pesquisar.")
                                                    }
                                                }
                                            }}
                                            fullWidth
                                            inputProps={{
                                                style: ({ fontSize: 16 })
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton size="small" style={{ marginRight: -10 }} onClick={() => {
                                                        if (palavras.length > 0) {
                                                            setDataAnuncios(null);
                                                            setBuscando(true);
                                                            buscaDadosAnunciosPesquisa(raio);
                                                        }
                                                        else {
                                                            setMensagem("Digite ao menos uma palavra para pesquisar.")
                                                        }
                                                    }}>
                                                        <img
                                                            style={{ width: 22, height: 23, resizeMode: 'stretch' }}
                                                            src={search2}
                                                        />
                                                    </IconButton>
                                                ),
                                                classes: {
                                                    input: classes.fonteMenorTextField,
                                                }
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    :
                                        null
                                    }
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" xs={12} style={{ height: '100%', width: baseLargura }} alignItems="flex-start" justifyContent="flex-start">
                                <>
                                {dataAnuncios != null ?
                                    <Grid container item direction="column" alignItems="center" justifyContent="flex-start">
                                        <Grid container item direction='column' alignItems="center" justifyContent="center" style={{ height: 20 }}>
                                            {dataAnunciosCategorias == null && dataAnunciosPesquisa == null ?
                                                <>
                                                    <Redirect to="/" />
                                                    <p className="FonteDicas" style={{ color: 'red', maxWidth: baseLargura * 0.9, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Destaques de {dataAnunciosDestaques[0].cidade_uf}</p>
                                                </>
                                            :
                                                dataAnunciosPesquisa != null ?
                                                    <p className="FonteDicas" style={{ color: 'red', maxWidth: baseLargura * 0.9, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Anúncios encontrados para: {palavrasClone} (relevância)</p>
                                                :
                                                    dataAnunciosCategorias != null && subcatSel != "" ?
                                                        <>
                                                            {convertePalavra(dataCategorias.filter(x => x.id == subcatSel)[0].subcategoria) != wSubCat ?
                                                                <Redirect to={"/" + convertePalavra(dataCategorias.filter(x => x.id == subcatSel)[0].subcategoria)} />
                                                            :
                                                                null
                                                            }
                                                            <p className="FonteDicas" />
                                                        </>
                                                    :
                                                        <p className="FonteDicas" />
                                            }
                                        </Grid>
                                        <Grid container item direction="column" ref={wRefListAnuncios} style={{ width: baseLargura, backgroundColor: '#F5F5F5', borderStyle: 'solid', borderWidth: 1, borderColor: 'silver', borderRadius: 5, padding: 5, paddingBottom: 0 }}>
                                            {mostraRotuloInstalarApp ?
                                                <Grid container item direction="row" xs={12} style={{ marginBottom: 4 }}>
                                                    <Card variant="outlined" style={{
                                                        width: 0,
                                                        height: 0,
                                                        backgroundColor: 'transparent',
                                                        borderRadius: 0,
                                                        borderStyle: 'solid',
                                                        borderTopWidth: 30,
                                                        borderLeftWidth: 15,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderTopColor: 'black',
                                                        borderLeftColor: 'transparent',
                                                        margin: 0
                                                    }} />
                                                    <Card variant="outlined" style={{ width: baseLargura - 45, backgroundColor: 'black', height: 30, borderRadius: 0, borderWidth: 0, margin: 0 }}>
                                                        <Grid container item direction="row" style={{ height: '100%', paddingLeft: 10, paddingRight: 10 }} justifyContent="space-between" alignItems="center">
                                                            <span style={{ color: 'white', fontSize: 13 }}>Instalar o App do iQueest</span>
                                                            <div />
                                                            <Grid container item direction="row" xs style={{ height: '100%' }} justifyContent="flex-end" alignItems="center">
                                                                <Button onClick={() => { 
                                                                    setMsgDlgInstalarApp("Sua experiência no iQueest será muito mais completa se você optar pela instalação do App em seu dispositivo. Tenha sua conta de usuário, seus Anúncios favoritos, e aproveite Promoções. Vá até sua loja de aplicativos através de um dos botões abaixo, e instale agora mesmo!");
                                                                    setMostraDlgInstalarApp(true);
                                                                }} style={{ textTransform: 'none', fontSize: 12, backgroundColor: 'black', color: 'white', width: 90, height: 25, marginRight: 30, borderStyle: 'solid', borderRadius: 50, borderWidth: 1.5, borderColor: 'white' }}><span style={{ lineHeight: 1.2 }}>Saiba mais</span></Button>
                                                                <IconButton style={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'white', borderRadius: 5, padding: 0 }} size="small" onClick={() => setMostraRotuloInstalarApp(false)}>
                                                                    <img
                                                                        style={{ width: 15, height: 15, resizeMode: 'stretch' }}
                                                                        src={closeWhite}
                                                                    />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                    <Card variant="outlined" style={{
                                                        width: 0,
                                                        height: 0,
                                                        backgroundColor: 'transparent',
                                                        borderRadius: 0,
                                                        borderStyle: 'solid',
                                                        borderTopWidth: 30,
                                                        borderRightWidth: 15,
                                                        borderLeftWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderTopColor: 'black',
                                                        borderRightColor: 'transparent',
                                                        margin: 0
                                                    }} />
                                                </Grid>
                                            :
                                                null
                                            }

                                            <Route path={subcatSel == "" ? "/" : "/" + convertePalavra(dataCategorias.filter(x => x.id == subcatSel)[0].subcategoria)}>
                                                <anunciosContext.Provider value={setAltura}>
                                                    <List className={classes.scrollbar}
                                                        itemKey={(index, data) => {
                                                            const { dataAnuncios, informaClicouFavorito, informaClicouPromocao } = data;
                                                            return dataAnuncios[index].id.toString();
                                                        }}
                                                        itemData={{ dataAnuncios, informaClicouFavorito, informaClicouPromocao }}
                                                        ref={wListRef}
                                                        height={alturaListaAnuncios - (34 * mostraRotuloInstalarApp) || 360}
                                                        width={baseLargura}
                                                        itemCount={dataAnuncios.length}
                                                        itemSize={getAltura}>

                                                        {renderItem}
                                                    </List>
                                                </anunciosContext.Provider>
                                            </Route>
                                        </Grid>
                                    </Grid>
                                :
                                    <Grid container direction="column" alignItems="center" justifyContent="flex-start" style={{ height: baseLargura / 4 * 7, width: baseLargura }}>
                                        {buscando ?
                                            <Grid item style={{ marginTop: 30, marginLeft: '-40%' }}>
                                                <img
                                                    style={{ position: 'absolute', width: 150, height: 91, resizeMode: 'stretch' }}
                                                    src={logoSemLupa} alt="logoSemLupa"
                                                />
                                                <img
                                                    style={{ position: 'absolute', width: 82, height: 82, resizeMode: 'contain', alignSelf: 'flex-start', marginLeft: '0.8%', marginTop: '0.5%' }}
                                                    src={lupaBuscando} alt="buscando"
                                                />
                                            </Grid>
                                        :
                                            infoNaoCarrega ?
                                                <Card variant="elevation" elevation={4} style={{ marginTop: 30, height: baseLargura / 4 * 2.5, width: '92%', padding: wUnidadeLargura * 10 }}>
                                                    <Grid container direction="column" alignItems="center" justifyContent="space-between">
                                                        <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                                                            <img
                                                                style={{ marginLeft: wUnidadeLargura * 8, width: wUnidadeLargura * 40, height: wUnidadeLargura * 45, resizeMode: 'stretch' }}
                                                                src={logoiQueest}
                                                            />
                                                            <p className={baseLargura < 350 ? "FonteTitulos_Mob" : "FonteTitulos"} style={{ marginLeft: wUnidadeLargura * 10 }}>Não podemos carregar o iQueest</p>
                                                        </Grid>
                                                        <p className={baseLargura < 350 ? "FonteAnuncio_Mob" : "FonteAnuncio"} style={{ textAlign: 'center', marginTop: 10 }}>Como a sua localização está inacessível, o iQueest não poderá ser carregado por aqui. Sugerimos que você utilize nosso aplicativo, pois o iQueest na versão em aplicativo oferece mais recursos e alternativas para aqueles que não querem permitir o acesso à sua localização.</p>
                                                        <p style={{ marginLeft: 15, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>Equipe iQueest</p>
                                                    </Grid>
                                                </Card>
                                            :
                                                <Grid container item direction="column" style={{ width: baseLargura }} alignItems="center">
                                                    <p className={baseLargura < 350 ? "FonteAnuncio_Mob" : "FonteAnuncio"} style={{ color: '#F9C205', textAlign: 'center', marginTop: 10 }}>Conectando ao serviço. Aguarde...</p>
                                                </Grid>
                                        }
                                    </Grid>
                                }
                                {mostraSubcats ?
                                    <ClickAwayListener onClickAway={ctrlClicouForaSubcats}>
                                        <Card variant="elevation" elevation={15} style={{ position: "absolute", zIndex: 10, marginTop: -40, marginLeft: 30, width: baseLargura * 0.45, height: isMobile ? baseLargura * 1.3 : baseLargura * 1.05, borderRadius: 10, display: 'flex', flexDirection: 'row' }} >
                                            <Paper style={{ height: '100%', width: '5%', backgroundColor: '#F9C205', borderTopStartRadius: 10, borderBottomStartRadius: 10 }}>
                                            </Paper>

                                            <List className={classes.scrollbar2} style={{ position: 'relative', width: '95%', height: '100%' }}
                                                itemKey={(index, data) => {
                                                    return data[index].id.toString();
                                                }}
                                                itemData={dataCategorias.filter(x => x.categoria === categoriaSel)}
                                                height={isMobile ? baseLargura * 1.3 : baseLargura * 1.05}
                                                width={baseLargura * 0.45 * 0.95}
                                                itemCount={dataCategorias.filter(x => x.categoria === categoriaSel).length}
                                                itemSize={() => 35}>

                                                {renderSubcat}
                                            </List>
                                        </Card>
                                    </ClickAwayListener>
                                :
                                    null
                                }
                                {mostraCardArea && dataAnuncios != null ?
                                    <Card variant="elevation" elevation={15} style={{ position: "absolute", zIndex: 11, backgroundColor: '#F9C205', marginTop: alturaListaAnuncios - 30, marginLeft: baseLargura * 0.02, width: baseLargura * 0.96, height: 50, borderStyle: 'solid', borderWidth: 1, borderColor: '#696969', borderRadius: 10 }}>
                                        <Grid container item direction="row" xs style={{ height: '100%', paddingLeft: 10, paddingRight: 10 }} justifyContent="space-between" alignItems="center">
                                            <span style={{ color: 'black', fontSize: 13 }}>Abrir Área do Anunciante</span>
                                            <div/>
                                            <Grid container item direction="row" xs style={{ height: '100%' }} justifyContent="flex-end" alignItems="center">
                                                <Button onClick={() => { window.open("https://www.iqueest.com.br/areadoanunciante/", "_blank"); }} style={{ textTransform: 'none', fontSize: 12, backgroundColor: '#F9C205', width: 110, height: 36, marginRight: 30, borderStyle: 'solid', borderRadius: 4, borderWidth: 1, borderColor: 'black' }}><span style={{ lineHeight: 1.2 }}>Quero anunciar Grátis</span></Button>
                                                <IconButton style={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'black', borderRadius: 5, padding: 0 }} size="small" onClick={() => setMostraCardArea(false)}>
                                                    <img
                                                        style={{ width: 20, height: 20, resizeMode: 'stretch' }}
                                                        src={close}
                                                    />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                :
                                    null
                                }
                                {mostraCardSite && dataAnuncios != null ?
                                    <Card variant="elevation" elevation={15} style={{ position: "absolute", zIndex: 11, backgroundColor: '#464646', marginTop: alturaListaAnuncios - 90, marginLeft: baseLargura * 0.02, width: baseLargura * 0.96, height: 50, borderStyle: 'solid', borderWidth: 1, borderColor: '#C0C0C0', borderRadius: 10 }}>
                                        <Grid container item direction="row" xs style={{ height: '100%', paddingLeft: 10, paddingRight: 10 }} justifyContent="space-between" alignItems="center">
                                            <span style={{ color: '#F9C205', fontSize: 13 }}>Saiba mais sobre o iQueest!</span>
                                            <div />
                                            <Grid container item direction="row" xs style={{ height: '100%' }} justifyContent="flex-end" alignItems="center">
                                                <Button onClick={() => { window.open("https://www.iqueest.net.br/", "_blank"); }} style={{ textTransform: 'none', fontSize: 12, backgroundColor: '#464646', width: 110, height: 36, marginRight: 30, borderStyle: 'solid', borderRadius: 4, borderWidth: 1, borderColor: '#F9C205' }}><span style={{ lineHeight: 1.2, color: '#F9C205' }}>Abrir Site</span></Button>
                                                <IconButton style={{ borderStyle: 'solid', borderWidth: 1, borderColor: '#F9C205', borderRadius: 5, padding: 0 }} size="small" onClick={() => setMostraCardSite(false)}>
                                                    <img
                                                        style={{ width: 20, height: 20, resizeMode: 'stretch' }}
                                                        src={closeCorEsq}
                                                    />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                :
                                    null
                                }
                                {mostraDlgInstalarApp && dataAnuncios != null ?
                                    <ClickAwayListener onClickAway={ctrlClicouForaDlgInstalarApp}>
                                        <Card variant="elevation" elevation={20} style={{ position: "absolute", zIndex: 10, backgroundColor: '#EBEBEB', padding: 20, marginTop: 40, marginLeft: baseLargura * 0.2 / 4, width: baseLargura * 0.8, height: 250, borderRadius: 5, boxShadow: '0.4em 0.4em 1.1em 0.1em gray' }} >
                                            <Grid container item direction="column" style={{ height: '100%', width: '100%' }} justifyContent="space-between" alignItems="center">
                                                <p style={{ textTransform: 'none', fontSize: 14, color: 'black', verticalAlign: 'center' }}>{msgDlgInstalarApp}</p>
                                                <p> </p>
                                                <Grid container item direction="row" style={{ width: '100%' }} justifyContent="center" alignItems="center">
                                                    <Button onClick={() => { window.open("https://downloads.iqueest.com.br/app/iqueest", "_blank"); }}>
                                                        <img
                                                            style={{ width: 130, height: 45, resizeMode: 'stretch' }}
                                                            src={botaoGoogle}
                                                        />
                                                    </Button>
                                                    <Button onClick={() => { window.open("https://downloads.iqueest.com.br/app/iqueest", "_blank"); }}>
                                                        <img
                                                            style={{ width: 130, height: 45 , resizeMode: 'stretch' }}
                                                            src={botaoApple}
                                                        />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </ClickAwayListener>
                                :
                                    null
                                }
                                </>
                            </Grid>
                            </Router>
                            </>
                        }
                    </Grid>
                </Grid>
            </div>
            <Dialog open={mensagem.length > 0} fullWidth={true} maxWidth="xs">
                <DialogContent>
                    <DialogContentText>{mensagem}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setMensagem("");
                        if (acao == -1) {
                            setAcao(1);
                        }
                    }} color="primary" style={{ textTransform: 'none' }}>OK</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
        </dadosGlobaisContext.Provider>
    );
}

export default App;
